import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainLayout = styled.div`
  max-width: 1440px;
  min-height: 100%;
  width: 100%;
  margin: auto;
  background: url(/images/bg-header.svg) no-repeat top right;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const MainHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  padding-bottom: 18px;
  box-sizing: content-box;
  background: url(/images/bg-header-line.svg) no-repeat center bottom;

  > a.logo {
    position: absolute;
    display: block;
    left: 0;
    top: 0;

    &.center {
      left: calc(50% - 190px / 2);
      top: -36px;
    }
  }

  @media (max-width: 768px) {
    justify-content: ${(props) => (props.theme.assets.logoPosition === "center" ? "center" : " flex-start;")};
  }

  @media (max-width: 540px) {
    height: 140px;
    > a.logo.center {
      left: calc(50% - 140px / 2);
    }
  }
`;

export const MainTitle = styled.h1`
  font-weight: 700;
  font-size: 46px;
  line-height: 58px;
  margin: 0 ${(props) => (props.theme.assets.logoPosition === "center" ? "16px" : "190px")};
  margin-top: ${(props) => (props.theme.assets.logoPosition === "center" ? "52px" : 0)};

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 54px;
    margin-right: 16px;
    text-align: ${(props) => (props.theme.assets.logoPosition === "center" ? "center" : "left")};
  }

  @media (max-width: 540px) {
    font-size: 26px;
    line-height: 38px;
    margin-left: ${(props) => (props.theme.assets.logoPosition === "center" ? "16px" : "140px")};
  }
`;

export const MainLogo = styled.img`
  width: 190px;
  height: 190px;

  @media (max-width: 540px) {
    width: 140px;
    height: 140px;
  }
`;

export const Navigation = styled.nav`
  margin: 6px 16px 70px 16px;
  text-align: center;
  color: #684d00;
  gap: 6px;

  > a {
    color: ${(props) => props.theme.colors.action};
    white-space: nowrap;
  }

  > span {
    white-space: nowrap;
  }

  @media (max-width: 540px) {
    margin-bottom: 24px;
  }
`;

export const HomeLink = styled(Link)`
  display: inline-block;
  padding-left: 24px;
  background-size: 20px;
  position: relative;

  svg {
    position: absolute;
    left: 0;
    top: 2px;
  }
`;
