import { createGlobalStyle } from "styled-components";

// CSS Reset: https://www.joshwcomeau.com/css/custom-css-reset
export const StyleReset = createGlobalStyle`
  /*
    Made by Elly Loel - https://ellyloel.com/
    With inspiration from:
      - Josh W Comeau - https://courses.joshwcomeau.com/css-for-js/treasure-trove/010-global-styles/
      - Andy Bell - https://piccalil.li/blog/a-modern-css-reset/
      - Adam Argyle - https://unpkg.com/open-props@1.3.16/normalize.min.css / https://codepen.io/argyleink/pen/KKvRORE
  */

  :root {
    font-size: 16px;
  }

  * {
    /* Remove default margin on everything */
    margin: 0;
    /* Remove default padding on everything */
    padding: 0;
    line-height: calc(0.25rem + 1em + 0.25rem);
  }

  /* Use a more-intuitive box-sizing model on everything */
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  /* Remove border and set sensible defaults for backgrounds, on all elements except fieldset progress and meter */
  :not(fieldset, progress, meter) {
    border-width: 0;
    border-style: solid;
    background-origin: border-box;
    background-repeat: no-repeat;
  }

  html {
    /* Allow percentage-based heights in the application */
    block-size: 100%;
    /* Making sure text size is only controlled by font-size */
    -webkit-text-size-adjust: none;
  }

  /* Smooth scrolling for users that don't prefer reduced motion */
  @media (prefers-reduced-motion: no-preference) {
    html:focus-within {
      scroll-behavior: smooth;
    }
  }

  body {
    /* Improve text rendering */
    -webkit-font-smoothing: antialiased;
    /* https://marco.org/2012/11/15/text-rendering-optimize-legibility */
    text-rendering: optimizeSpeed;
    /* Allow percentage-based heights in the application */
    min-block-size: 100%;
    -moz-osx-font-smoothing: grayscale;
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter#example_2 */
    /* scrollbar-gutter: stable both-edges; Removed until this bug is fixed: https://bugs.chromium.org/p/chromium/issues/detail?id=1318404#c2 */
  }

  /* Improve media defaults */
  img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
  }
  img, svg, video {
    block-size: auto;
    max-inline-size: 100%;
  }

  /* Remove stroke and set fill colour to the inherited font colour */
  svg {
    stroke: none;
    fill: currentColor;
  }

  /* Remove built-in form typography styles */
  input, button, textarea, select,
  input[type="file"]::-webkit-file-upload-button {
    color: inherit;
    font: inherit;
    font-size: inherit;
    letter-spacing: inherit;
  }

  /* Change textarea resize to vertical only and block only if the browser supports that */
  textarea {
    resize: vertical;
  }
  @supports (resize: block) {
    textarea {
      resize: block;
    }
  }

  /* Avoid text overflows */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  /* Fix h1 font size inside article, aside, nav, and section */
  h1 {
    font-size: 2em;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul, ol[role="list"] {
    list-style: none;
  }

  /* More readable underline style for anchor tags without a class. This could be set on anchor tags globally, but it can cause conflicts. */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make it clear that interactive elements are interactive */
  a[href], area, button, input, label[for], select, summary, textarea, [tabindex]:not([tabindex*="-"]) {
    cursor: pointer;
    touch-action: manipulation;
  }
  input[type="file"] {
    cursor: auto;
  }
  input[type="file"]::-webkit-file-upload-button,
  input[type="file"]::file-selector-button {
    cursor: pointer;
  }

  /* Animate focus outline */
  @media (prefers-reduced-motion: no-preference) {
    :focus-visible {
      transition: outline-offset 145ms cubic-bezier(0.25, 0, 0.4, 1);
    }
    :not(:active):focus-visible {
      transition-duration: 0.25s;
    }
  }
  :not(:active):focus-visible {
    outline-offset: 5px;
  }

  /* Make sure users can't select button text */
  button, button[type], input[type="button"], input[type="submit"], input[type="reset"],
  input[type="file"]::-webkit-file-upload-button,
  input[type="file"]::file-selector-button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
    text-align: center;
  }

  /* Disabled cursor for disabled buttons */
  button[disabled], input[type="button"][disabled], input[type="submit"][disabled], input[type="reset"][disabled] {
    cursor: not-allowed;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.body.fontFamily};
    color: ${(props) => props.theme.colors.text};
    background: url(/images/bg-bottom.svg) no-repeat bottom center fixed, ${(props) =>
      `linear-gradient(180deg, ${props.theme.body.backgroundColor1} 0%, ${props.theme.body.backgroundColor2} 53.12%) fixed`};
    min-height: 100vh;
  }

  #root {
    height: 100vh;
  }
`;
