import { DefaultTheme } from "styled-components";

const MainTheme: DefaultTheme = {
  assets: {
    logo: "",
    logoPosition: "left",
  },
  body: {
    fontFamily: "Mulish, Roboto, 'Helvetica Neue', sans-serif",
    backgroundColor1: "#FFFFFF",
    backgroundColor2: "#FFFFFF",
  },
  colors: {
    text: "#29261A",
    action: "#6a6a6a",
    actionBright: "#9f9f9f",
  },
  page: {
    width: "960px",
  },
};

export { MainTheme };
