import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const MainPage = styled.main`
  padding-bottom: 80px;
  margin: 0 38px;
  @media (max-width: 540px) {
    margin: 0 20px;
  }
`;

export const NavButtons = styled.div`
  text-align: center;
  margin-top: 100px;

  > a:not(:nth-child(1)) {
    margin-left: 36px;
  }

  @media (max-width: 768px) {
    margin-top: 44px;
    > a:not(:nth-child(1)) {
      margin-left: 0;
    }
  }
`;

export const IntroText = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  max-width: ${(props) => props.theme.page.width};
  margin: 70px auto;

  > p {
    margin-bottom: 28px;
  }

  @media (max-width: 540px) {
    margin: 24px auto;
    font-size: 18px;
  }
`;

const navButtonStyle = css`
  background: white;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  padding: 21px 30px 21px 70px;
  background: left 16px center no-repeat;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: inline-block;
  margin-bottom: 26px;
  position: relative;

  @media (max-width: 540px) {
    font-size: 18px;
    width: 90%;
    background: left 8px center no-repeat;
    background-size: 30px;
    padding: 16px 8px 16px 40px;
  }

  svg {
    position: absolute;
    left: 16px;
    top: 13px;
  }
`;

export const NavButton = styled(Link)`
  ${navButtonStyle}
`;

export const DownloadReportButton = styled.a`
  ${navButtonStyle}
  background-image: url("/images/button-download.svg") !important;
  color: white;
  background-color: ${(props) => props.theme.colors.action} !important;
  &:hover {
    background-color: ${(props) => props.theme.colors.actionBright};
  }
`;

export const ExistingPortfolioButton = styled(NavButton)`
  background-color: white;
  &:hover {
    color: ${(props) => props.theme.colors.action};
  }
`;

export const NewLocationButton = styled(NavButton)`
  background-color: white;
  &:hover {
    color: ${(props) => props.theme.colors.action};
  }
`;

export const LoadingPage = styled.div`
  text-align: center;
`;
