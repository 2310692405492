import React, { createContext, useCallback, useContext, useState } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { GlobalStyle } from "./index.styled";
import { MainTheme } from "./theme";

const ThemeUpdateContext = createContext((changes: Partial<DefaultTheme>) =>
  console.error("attempted to set theme outside of a ThemeUpdateContext.Provider")
);

export default function AppThemeProvider({ children }: any) {
  const [theme, setTheme] = useState(MainTheme);

  const updateTheme = useCallback(
    (changes: Partial<DefaultTheme>) => {
      setTheme({ ...theme, ...changes });
    },
    [theme, setTheme]
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ThemeUpdateContext.Provider value={updateTheme}>{children}</ThemeUpdateContext.Provider>
    </ThemeProvider>
  );
}

export const useUpdateTheme = () => useContext(ThemeUpdateContext);
