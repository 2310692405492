import styled from "styled-components";

export const BuildingPage = styled.main`
  max-width: ${(props) => props.theme.page.width};
  margin: auto;
  margin-top: 80px;
  border-radius: 16px;
  background: white;
  position: relative;
  padding: 36px 50px;

  @media (max-width: 1024px) {
    margin: 0 38px;
  }

  @media (max-width: 768px) {
    margin: 0 20px;
    padding: 36px 16px;
  }
`;

export const BackButton = styled.button`
  background: none;
  height: 48px;
  width: 48px;
  display: block;
  position: absolute;
  top: 30px;
  left: 24px;
`;

export const GapAnalysisButton = styled.button`
  background: none;
  height: 48px;
  width: 48px;
  display: block;
  position: absolute;
  top: 30px;
  right: 40px;
  div {
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    bottom: -40px;
    width: 90px;
    height: 36px;
    left: -24px;
    text-align: center;
    line-height: 18px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.action};
  }
  @media (max-width: 768px) {
    div {
      display: none;
    }
  }
`;

export const BuildingTitle = styled.h2`
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  margin: 0 80px;

  @media (max-width: 540px) {
    font-size: 28px;
  }
`;

export const BuildingAddress = styled.p`
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #68665e;
  margin-top: 18px;

  @media (max-width: 540px) {
    font-size: 18px;
    margin-top: 8px;
  }
`;

export const BuildingAttributes = styled.div`
  display: flex;
  justify-content: center;
  width: 654px;
  margin: auto;
  margin-top: 56px;
  margin-bottom: 60px;

  > div:nth-child(1) {
    border: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 540px) {
    flex-direction: column;
    margin-top: 42px;
    margin-bottom: 42px;
  }
`;

export const WalkScore = styled.div`
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #68665e;
    text-transform: uppercase;
    margin-bottom: 10px;

    span {
      text-transform: none;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    color: #fd7900;
  }

  @media (max-width: 540px) {
    h4 {
      font-size: 14px;
    }

    h5 {
      font-size: 26px;
    }
  }
`;

export const Activities = styled.div`
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  width: 78%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 54px;

  strong {
    font-weight: 700;
  }

  @media (max-width: 540px) {
    font-size: 18px;
    width: 90%;
  }
`;

export const ServiceTactics = styled.div`
  border-top: 1px solid #cbcbcb;
`;

export const TacticsTitle = styled.h3`
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  margin-top: 40px;

  @media (max-width: 540px) {
    font-size: 24px;
  }
`;

export const TacticsInstructions = styled.div`
  background: #f6f6f6;
  border-radius: 12px;
  padding: 30px;
  margin-top: 46px;

  p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 30px;
  }

  @media (max-width: 540px) {
    margin-top: 26px;

    p {
      font-size: 16px;
    }
  }
`;

export const TacticsControls = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

export const TypeFilter = styled.div`
  flex: 1 0 calc(50% - 15px);
  font-weight: 300;
  font-size: 18px;
  ul {
    font-weight: 400;
    font-size: 20px;
    margin-left: 0px;
    margin-right: 100px;
    margin-top: 42px;
  }
  li {
    background: no-repeat url(/images/choose-circle.svg) left center;
    padding-left: 34px;
    margin-bottom: 14px;
    display: block;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      font-weight: 700;
    }

    &.selected {
      background: no-repeat url(/images/choose-circle-selected.svg) left center;
      font-weight: 700;
      color: ${(props) => props.theme.colors.action};
    }
  }
`;

export const SheetNotice = styled.p`
  font-weight: 300;
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
  color: #999999;
  a {
    color: inherit;
    &:hover {
      color: ${(props) => props.theme.colors.action};
    }
  }
`;

export const TacticsPriorities = styled.div`
  flex: 1 0 calc(50% - 15px);
  > div {
    margin-bottom: 10px;
  }
`;

export const TacticsGroups = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  align-items: flex-start;
  margin-top: 56px;
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity 0.8s 0.25s ease-out;
  }
`;

export const TacticsGroupItem = styled.li`
  box-shadow: 0px 2px 4px 2px rgba(114, 114, 114, 0.25);
  border-radius: 8px;
  flex-grow: 1;
  max-width: calc(50% - 20px);
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const TacticGroup = styled.div`
  background: #f5f5f5 no-repeat left 18px center var(--bg-image);
  background-size: 54px;
  font-weight: 600;
  font-size: 24px;
  min-height: 88px;
  padding: 6px 20px 6px 90px;
  display: flex;
  align-items: center;

  &.highlighted {
    background: no-repeat left 18px center var(--bg-image),
      linear-gradient(90deg, #ffe68b 5.47%, rgba(255, 243, 139, 0.51) 90.65%);
  }
`;

export const TacticList = styled.ul``;

export const Tactic = styled.li`
  border-bottom: 1px solid #d1d1d1;
  display: flex;
  padding: 10px 15px;
  min-height: 102px;
  align-items: flex-start;
  gap: 8px;

  button {
    visibility: hidden;
  }

  &.has-sheet:hover {
    h6 {
      color: ${(props) => props.theme.colors.action};
    }
    cursor: pointer;
    button path {
      fill: ${(props) => props.theme.colors.action};
    }
  }

  &.has-sheet {
    button {
      visibility: visible;
    }
  }
`;

export const TacticInfo = styled.div`
  flex-grow: 1;
`;

export const TacticName = styled.h6`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const TacticCategory = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #999999;
  margin-bottom: 3px;
  text-transform: uppercase;

  img {
    display: inline-block;
    width: 13px;
    height: auto;
    margin-left: 10px;
    vertical-align: sub;
    margin-bottom: 1px;
  }
`;

export const TacticDescription = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
`;
