import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { useUpdateTheme } from "../AppThemeProvider";
import type { SheetData } from "../types/sheets";
import { Api } from "./api";

type Status = "ready" | "cache-read" | "fetching-api" | "api-fetched";

const DATA_KEY = "sheet_data";
const EXPIRATION_KEY = "sheet_data_expiration_time";
const CACHE_DURATION = 60 * 60 * 2; // 2 hours

export function useSheetData() {
  const updateTheme = useUpdateTheme();
  const theme = useContext(ThemeContext);
  const [data, setData] = useState<SheetData | null>(null);
  const [status, setStatus] = useState<Status>("ready");
  const [error, setError] = useState<string | null>(null);
  const [themeUpdated, setThemeUpdated] = useState(false);

  const clearCache = useCallback(() => {
    localStorage.removeItem(EXPIRATION_KEY);
    localStorage.removeItem(DATA_KEY);
  }, []);

  const updateCache = useCallback((newData: SheetData) => {
    localStorage.setItem(DATA_KEY, JSON.stringify(newData));
  }, []);

  const loadFromCache = useCallback(() => {
    const expirationTime = localStorage.getItem(EXPIRATION_KEY);
    const cachedData = localStorage.getItem(DATA_KEY);
    if (!expirationTime || !cachedData || Number(expirationTime) < Date.now()) {
      clearCache();
      setStatus("cache-read");
      return;
    }
    try {
      const data = JSON.parse(cachedData);
      setData(data);
    } catch (e) {
      console.error(e);
    }
    setStatus("cache-read");
  }, [clearCache]);

  const fetchApi = useCallback(async (password: string) => {
    try {
      setStatus("fetching-api");
      const data = await Api.getSheetData(password);
      setData(data);
      setStatus("api-fetched");
      localStorage.setItem(EXPIRATION_KEY, (Date.now() + 1000 * CACHE_DURATION).toString());
      localStorage.setItem(DATA_KEY, JSON.stringify(data));
    } catch (e: any) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError(e.toString());
      }
      setStatus("api-fetched");
    }
  }, []);

  useEffect(() => {
    loadFromCache();
  }, [loadFromCache]);

  useEffect(() => {
    if (data && !themeUpdated) {
      setThemeUpdated(true);
      const metadata = data.metadata;
      updateTheme({
        assets: {
          ...theme.assets,
          logo: metadata["Logo Url"],
          logoPosition: metadata["Logo Position"] as any,
        },
        colors: {
          ...theme.colors,
          action: metadata["Action Color"],
          actionBright: metadata["Action Color Active"],
        },
        body: {
          ...theme.body,
          backgroundColor1: metadata["Background Color 1"],
          backgroundColor2: metadata["Background Color 2"],
        },
      });
    }
  }, [data, updateTheme, themeUpdated, theme]);

  return { data, status, error, fetchApi, clearCache, updateCache };
}
