import React, { useContext } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { HomeLink, MainHeader, MainLayout, MainLogo, MainTitle, Navigation } from "./App.styled";
import Building from "./pages/Building";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Portfolio from "./pages/Portfolio";
import Footer from "./components/Footer";
import Explore from "./pages/Explore";
import { ThemeContext } from "styled-components";

function App() {
  const location = useLocation();
  const theme = useContext(ThemeContext);
  return (
    <MainLayout>
      <MainHeader>
        <Link to="/" className={`logo ${theme.assets.logoPosition === "center" ? "center" : "left"}`}>
          {theme.assets.logo && <MainLogo src={theme.assets.logo} />}
        </Link>
        <MainTitle>Employee Services Strategy</MainTitle>
      </MainHeader>
      <Navigation>
        {location.pathname.startsWith("/main") && <>&nbsp;</>}
        {location.pathname.startsWith("/portfolio") && (
          <>
            <HomeLinkComponent /> &gt; <span>Existing Portfolio</span>
          </>
        )}
        {location.pathname.startsWith("/building") && (
          <>
            <HomeLinkComponent /> &gt;{" "}
            {location.pathname !== "/building/0" && <Link to="/portfolio">Existing Portfolio</Link>}
            {location.pathname === "/building/0" && <Link to="/explore">New Location</Link>} &gt;{" "}
            <span>Tactics for Building</span>
          </>
        )}
        {location.pathname.startsWith("/explore") && (
          <>
            <HomeLinkComponent /> &gt; <span>New Location</span>
          </>
        )}
      </Navigation>
      <Routes location={location}>
        <Route path="/" element={<Login />}></Route>
        <Route path="/main" element={<Main />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/explore" element={<Explore />}></Route>
        <Route path="/building/:buildingId" element={<Building />}></Route>
      </Routes>
      <Footer></Footer>
    </MainLayout>
  );
}

export default App;

function HomeLinkComponent() {
  const theme = useContext(ThemeContext);
  return (
    <HomeLink to="/main">
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={theme.colors.action}>
        <path
          fillRule="evenodd"
          d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
          clipRule="evenodd"
        />
      </svg>
      Home
    </HomeLink>
  );
}
