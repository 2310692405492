import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ThemeContext } from "styled-components";
import { useSheetData } from "../services/useSheetData";
import {
  DownloadReportButton,
  ExistingPortfolioButton,
  IntroText,
  LoadingPage,
  MainPage,
  NavButtons,
  NewLocationButton,
} from "./Main.styled";

function Main() {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { data, status } = useSheetData();

  useEffect(() => {
    if (status === "cache-read" && !data) {
      navigate("/");
    }
  }, [status, data, navigate]);

  if (!data) {
    return (
      <LoadingPage>
        <BeatLoader color="#ffffff" />
      </LoadingPage>
    );
  }

  const intro1 = data.metadata["Intro Paragraph 1"];
  const intro2 = data.metadata["Intro Paragraph 2"];
  const intro3 = data.metadata["Intro Paragraph 3"];

  return (
    <MainPage>
      <IntroText>
        <p>{intro1}</p>
        {intro2 && <p>{intro2}</p>}
        {intro3 && <p>{intro3}</p>}
      </IntroText>
      <NavButtons>
        <DownloadReportButton href={data.metadata["Full Report Url"]} target="_blank">
          Download Full Strategy Report
        </DownloadReportButton>
        <ExistingPortfolioButton to={"/portfolio"}>
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 36.75H24.5M33.25 36.75V8.75C33.25 7.82174 32.8813 6.9315 32.2249 6.27513C31.5685 5.61875 30.6783 5.25 29.75 5.25H12.25C11.3217 5.25 10.4315 5.61875 9.77513 6.27513C9.11875 6.9315 8.75 7.82174 8.75 8.75V36.75H33.25ZM33.25 36.75H36.75H33.25ZM33.25 36.75H24.5H33.25ZM8.75 36.75H5.25H8.75ZM8.75 36.75H17.5H8.75ZM15.75 12.25H17.5H15.75ZM15.75 19.25H17.5H15.75ZM24.5 12.25H26.25H24.5ZM24.5 19.25H26.25H24.5ZM17.5 36.75V28C17.5 27.5359 17.6844 27.0908 18.0126 26.7626C18.3408 26.4344 18.7859 26.25 19.25 26.25H22.75C23.2141 26.25 23.6592 26.4344 23.9874 26.7626C24.3156 27.0908 24.5 27.5359 24.5 28V36.75H17.5Z"
              stroke={theme.colors.action}
              strokeWidth="4.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </svg>
          Explore Existing Portfolio
        </ExistingPortfolioButton>
        <NewLocationButton to={"/explore"}>
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.75 36.75L26.25 26.25L36.75 36.75ZM29.75 17.5C29.75 19.1087 29.4331 20.7016 28.8175 22.1879C28.2019 23.6741 27.2996 25.0245 26.1621 26.1621C25.0245 27.2996 23.6741 28.2019 22.1879 28.8175C20.7016 29.4331 19.1087 29.75 17.5 29.75C15.8913 29.75 14.2984 29.4331 12.8121 28.8175C11.3259 28.2019 9.97546 27.2996 8.83794 26.1621C7.70042 25.0245 6.7981 23.6741 6.18248 22.1879C5.56686 20.7016 5.25 19.1087 5.25 17.5C5.25 14.2511 6.54062 11.1353 8.83794 8.83794C11.1353 6.54062 14.2511 5.25 17.5 5.25C20.7489 5.25 23.8647 6.54062 26.1621 8.83794C28.4594 11.1353 29.75 14.2511 29.75 17.5Z"
              stroke={theme.colors.action}
              strokeWidth="4.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </svg>
          Explore a New Location
        </NewLocationButton>
      </NavButtons>
    </MainPage>
  );
}

export default Main;
